import React from "react"
import InstagramGallery from "./galleryInstagram"

const INSTAGRAM_ID = "31298234939"
const THUMBNAIL_WIDTH = 640
const PHOTO_COUNT = 16

// import DztImageGalleryComponent from "reactjs-image-gallery"

// const photos = [
//   {
//     url: "https://source.unsplash.com/2ShvY8Lf6l0/600x599",
//     thumbUrl: "https://source.unsplash.com/2ShvY8Lf6l0/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/Dm-qxdynoEc/600x599",
//     thumbUrl: "https://source.unsplash.com/Dm-qxdynoEc/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/qDkso9nvCg0/600x599",
//     thumbUrl: "https://source.unsplash.com/qDkso9nvCg0/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/iecJiKe_RNg/600x599",
//     thumbUrl: "https://source.unsplash.com/iecJiKe_RNg/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/epcsn8Ed8kY/600x599",
//     thumbUrl: "https://source.unsplash.com/epcsn8Ed8kY/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/NQSWvyVRIJk/600x599",
//     thumbUrl: "https://source.unsplash.com/NQSWvyVRIJk/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/zh7GEuORbUw/600x599",
//     thumbUrl: "https://source.unsplash.com/zh7GEuORbUw/600x599",
//     title: "Lorem Image Title",
//   },
//   {
//     url: "https://source.unsplash.com/zh7GEuORbUw/600x599",
//     thumbUrl: "https://source.unsplash.com/zh7GEuORbUw/600x599",
//     title: "Lorem Image Title",
//   },
// ]

const ImageGallery = () => {
  return (
    <div>
      <h1
        style={{
          margin: "50px auto",
          display: "table",
          borderBottom: "2px solid #ffa41b",
          color: "#fff",
        }}
      >
        VISIT OUR GALLERY
      </h1>
      {/* <DztImageGalleryComponent hideDownload={true} images={photos} /> */}
      <InstagramGallery
        userId={INSTAGRAM_ID}
        thumbnailWidth={THUMBNAIL_WIDTH}
        photoCount={PHOTO_COUNT}
      />
    </div>
  )
}

export default ImageGallery
