import React from "react"
import { Grid } from "semantic-ui-react"
import Fade from "react-reveal/Fade"

const CardBlog = () => (
  <Fade>
    <div className="blog-card alt">
      <div className="meta">
        <div
          className="photo"
          style={{
            backgroundImage: `url("https://source.unsplash.com/600x300?Food,Plate,Vegan")`,
          }}
        ></div>
        <ul className="details">
          <li className="author">
            {/* eslint-disable-next-line */}
            <a href="#">Jane Doe</a>
          </li>
          <li className="date">July. 15, 2015</li>
          <li className="tags">
            <ul>
              <li>
                {/* eslint-disable-next-line */}
                <a href="#">Vegan</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a href="#">Salad</a>
              </li>
              <li>
                {/* eslint-disable-next-line */}
                <a href="#">Snacks</a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <div className="description">
        <h1>Vegan Salad with Cilantros</h1>
        <h2>Lorem ipsum Doler sit amet</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad eum
          dolorum architecto obcaecati enim dicta praesentium, quam nobis! Neque
          ad aliquam facilis numquam. Veritatis, sit.
        </p>
        <p className="read-more">
          {/* eslint-disable-next-line */}
          <a href="#">Read More</a>
        </p>
      </div>
    </div>
  </Fade>
)

const RecentPosts = () => {
  return (
    <div>
      <h1
        style={{
          margin: "50px auto",
          display: "table",
          borderBottom: "2px solid #ffa41b",
          color: "#fff",
        }}
      >
        RECENT POSTS
      </h1>
      <Grid columns={2} stackable stretched>
        <Grid.Row>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
          <Grid.Column>
            {" "}
            <CardBlog />{" "}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default RecentPosts
