import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import HeroSection from "../components/HomePage/hero"
// import AboutUs from "../components/HomePage/about-us"
import Featured from "../components/HomePage/featured"
import RecentPosts from "../components/HomePage/recents"
import ImageGallery from "../components/HomePage/gallery"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroSection />
    {/* <AboutUs /> */}
    <Featured />
    <RecentPosts />
    <ImageGallery />
  </Layout>
)

export default IndexPage
