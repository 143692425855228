import React from "react"
import { Divider } from "semantic-ui-react"
import Fade from "react-reveal/Fade"

const HeroSection = () => {
  return (
    <div>
      <Fade>
        <div className="hero-image">
          <div className="hero-text">
            <Fade top>
              <h1>Welcome to My Food Book</h1>
              <Divider style={{ borderColor: "#ffa41b" }} />
              <p>Lorem Ipsum Doler Sit Amet</p>
              <button>KNOW MORE</button>
            </Fade>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default HeroSection
