import React from "react"
import { Grid, Card } from "semantic-ui-react"
import Fade from "react-reveal/Fade"
import GoldenMilkImg from "../../images/1.jpeg"
import Salad from "../../images/5.jpeg"
import Chicken from "../../images/8.jpg"
import { Link } from "gatsby"

const Featured = () => {
  return (
    <div>
      <Fade>
        <h1
          style={{
            margin: "50px auto",
            display: "table",
            borderBottom: "2px solid #ffa41b",
            color: "#fff",
          }}
        >
          FEATURED
        </h1>
        <Grid centered columns={3} container stackable>
          <Grid.Row>
            <Grid.Column style={{ display: "flex", justifyContent: "center" }}>
              <Card
                header={<h2 style={{ textAlign: "center" }}>Golden Milk</h2>}
                image={
                  <img
                    src={GoldenMilkImg}
                    style={{ height: "250px", objectFit: "cover" }}
                    alt="Golden Milk"
                  />
                }
                description={
                  <p style={{ textAlign: "center", fontSize: "16px" }}>
                    Add milk to a saucepan on medium heat .when its little
                    lukewarm add ground turmeric and give it a nice whisk......
                  </p>
                }
                extra={
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "2px solid #ffa41b",
                      outline: "0",
                      padding: "10px 15px",
                      textAlign: "center",
                      margin: "20px auto",
                      display: "table",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    <Link to="/golden-milk"> READ MORE </Link>
                  </button>
                }
              ></Card>
            </Grid.Column>
            <Grid.Column style={{ display: "flex", justifyContent: "center" }}>
              <Card
                header={
                  <h2 style={{ textAlign: "center" }}>Easy Green Salad</h2>
                }
                image={
                  <img
                    src={Salad}
                    style={{ height: "250px", objectFit: "cover" }}
                    alt="Salad"
                  />
                }
                description={
                  <p style={{ textAlign: "center", fontSize: "16px" }}>
                    In a cup ,mix all the ingredients for dressing .adjust the
                    salt to tatse......
                  </p>
                }
                extra={
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "2px solid #ffa41b",
                      outline: "0",
                      padding: "10px 15px",
                      textAlign: "center",
                      margin: "20px auto",
                      display: "table",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    <Link to="/easy-green-salad"> READ MORE </Link>
                  </button>
                }
              ></Card>
            </Grid.Column>
            <Grid.Column style={{ display: "flex", justifyContent: "center" }}>
              <Card
                header={
                  <h2 style={{ textAlign: "center" }}>Roasted Chicken</h2>
                }
                image={
                  <img
                    src={Chicken}
                    style={{ height: "250px", objectFit: "cover" }}
                    alt="Chicken"
                  />
                }
                description={
                  <p style={{ textAlign: "center", fontSize: "16px" }}>
                    Season the chicken with salt and lemon juice and olive and
                    leave it......
                  </p>
                }
                extra={
                  <button
                    style={{
                      backgroundColor: "transparent",
                      border: "2px solid #ffa41b",
                      outline: "0",
                      padding: "10px 15px",
                      textAlign: "center",
                      margin: "20px auto",
                      display: "table",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                  >
                    <Link to="/roasted-chicken"> READ MORE </Link>
                  </button>
                }
              ></Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Fade>
    </div>
  )
}

export default Featured
